@import url("https://fonts.googleapis.com/css2?family=Vesper+Libre&display=swap");

:root {
  --wrapper-width: calc(66vw + 2px);
  --margin-y: 1rem;
}

@media only screen and (max-width: 600px) {
  :root {
    --wrapper-width: 100%;
    --margin-y: 0;
  }
}

body {
  margin: 0;
  overflow: hidden;
  font-family: "Vesper Libre", serif;
}

.diceWrap {
  position: absolute;
  width: 200px;
  height: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  z-index: 100;
}

.diceFace {
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: rgba(250, 0, 0, 0.4);
  border: 2px solid rgba(250, 0, 0, 0.2);
  border-radius: 30px;
  transform-style: preserve-3d;
  transition: 0.5s;
}

.diceFace::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(250, 0, 0, 0.2);
  border-radius: 20px;
  transform: translateZ(-1px);
}

.diceFace::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
  background-color: #fff;
  border-radius: 100%;
  transform: translateZ(1px);
}

.front {
  transform: translateZ(50px);
}

.front::after {
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  background-color: #fff;
}

.up {
  transform: rotateX(90deg) translateZ(50px);
}

.up::after {
  margin: -30px 0 0 -30px;
  box-shadow: 40px 40px #fff;
}

.left {
  transform: rotateY(-90deg) translateZ(50px);
}

.left::after {
  margin: -40px 0 0 -40px;
  box-shadow: 30px 30px #fff, 60px 60px #fff;
}

.right {
  transform: rotateY(90deg) translateZ(50px);
}

.right::after {
  margin: -30px 0 0 -30px;
  background-color: #fff;
  box-shadow: 40px 0px #fff, 0px 40px #fff, 40px 40px #fff;
}

.bottom {
  transform: rotateX(-90deg) translateZ(50px);
}

.bottom::after {
  margin: -36px 0 0 -36px;
  box-shadow: 26px 26px #fff, 52px 52px #fff, 52px 0px #fff, 0px 52px #fff;
}

.back {
  transform: rotateX(180deg) translateZ(50px);
}

.back::after {
  margin: -40px 0 0 -30px;
  box-shadow: 40px 0px #fff, 0px 30px #fff, 40px 30px #fff, 0px 60px #fff,
    40px 60px #fff;
}

.dice {
  position: absolute;
  width: 100px;
  height: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  transform-style: preserve-3d;
  transition: 3s cubic-bezier(0.42, 1.57, 0.62, 0.86);
  will-change: transform;
}

.face-1 {
  transform: rotate3d(-0.1, 0.3, -1, 180deg) rotateX(var(--rotation))
    rotateY(var(--rotation));
}

.face-2 {
  transform: rotate3d(0.3, 0.45, 0.9, 180deg) rotateX(var(--rotation))
    rotateY(var(--rotation));
}

.face-3 {
  transform: rotate3d(-0.8, 0.3, -0.75, 180deg) rotateX(var(--rotation))
    rotateY(var(--rotation));
}

.face-4 {
  transform: rotate3d(-0.85, -0.42, 0.73, 180deg) rotateX(var(--rotation))
    rotateY(var(--rotation));
}

.face-5 {
  transform: rotate3d(-0.1, 0.6, -0.4, 180deg) rotateX(var(--rotation))
    rotateY(var(--rotation));
}

.face-6 {
  transform: rotate3d(-0.16, 0.6, 0.18, 180deg) rotateX(var(--rotation))
    rotateY(var(--rotation));
}

.background {
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: -1;
  overflow: hidden;
}

.background > div {
  position: absolute;
  display: flex;
  inset: 0 0 0 0;
}

.lines {
  justify-content: space-around;
}

.lines > div {
  width: 1px;
  background-color: #d4d1d1;
}

.circle {
  justify-content: center;
  align-items: center;
}

.circle > div {
  border-radius: 100%;
  height: 66vh;
  border: 1px solid #d4d1d1;
  width: 66vh;
  position: absolute;
  margin: auto;
}

.logo {
  position: absolute;
  bottom: var(--margin-y);
  left: 8px;
  right: 8px;
  max-width: var(--wrapper-width);
  margin: auto;
  z-index: 150;
  text-align: center;
}

.logo > svg {
  max-width: 100%;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  max-width: var(--wrapper-width);
  margin: auto;
  padding-top: var(--margin-y);
  gap: 0.2rem;
}

.marquee span {
  will-change: transform;
  transform: translateX(0);
  white-space: nowrap;
  animation: marquee 15s linear infinite;
}

.instructions {
  position: absolute;
  bottom: calc(50% - 17.5vh);
  text-align: center;
  left: 0;
  right: 0;
  pointer-events: none;
}
